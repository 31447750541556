@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Maven Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f5f5f7;
  font-size: 18px;
  line-height: 1.3em;
  color: #2c3e50;
}
